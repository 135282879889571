import React from 'react';
import './solutions.css';

const callouts = [
    {
      name: 'Global Trade',
      description: 'Petrochemicals, Fertilizers and Chemicals',
      imageSrc: './images/oil.jpg',
      imageAlt: '.',
      href: '#',
    },
    {
      name: 'Proven Experience',
      description: 'Successful execution of large-scale projects on strategic locations in UAE, Turkmenistan and Turkey',
      imageSrc: './images/metal.jpeg',
      imageAlt: '',
      href: '#',
    },
    {
      name: 'Logistics and Transportation',
      description: 'Sales Department: Market-driven solutions and customer engagement',
      imageSrc: './images/chemical.jpeg',
      imageAlt: '',
      href: '/products',
    },
  ]
  
  export default function Solutions() {
    return (
      <div className="solutions-bg">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center mx-auto max-w-2xl py-16 sm:py-24 lg:max-w-none lg:py-28">
            <h2 className="text-4xl font-bold text-gray-200 py-8">What We Do</h2>
  
            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0">
              {callouts.map((callout) => (
                <div key={callout.name} className="group relative">
                  <div className="relative h-80 w-full overflow-hidden rounded-lg bg-white sm:aspect-h-1 sm:aspect-w-2 lg:aspect-h-1 lg:aspect-w-1 opacity-75 group-hover:opacity-100 sm:h-64">
                    <img
                      src={callout.imageSrc}
                      alt={callout.imageAlt}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <h3 className="mt-6 text-sm font-semibold text-gray-300">
                    <a href={callout.href}>
                      <span className="absolute inset-0" />
                      {callout.name}
                    </a>
                  </h3>
                  <p className="text-base text-gray-400">{callout.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  