import React from 'react';
import ProductsContainer from '../containers/Products/ProductsContainer';

const Products = () => {
  return (
    <div>
      <ProductsContainer/>
    </div>
  )
}

export default Products
