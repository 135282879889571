import React, { useState, useEffect } from "react";
import "./AboutUsContainer.css";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";

const AboutUsContainer = () => {
  // Counting effect state
  const [foundedYear, setFoundedYear] = useState(0);
  const [annualSales, setAnnualSales] = useState(0);
  const [countries, setCountries] = useState(0);

  useEffect(() => {
    // Function to handle counting effect
    const countToValue = (value, setter, duration = 2000) => {
      let start = 0;
      const increment = value / (duration / 100);
      const interval = setInterval(() => {
        start += increment;
        if (start >= value) {
          clearInterval(interval);
          setter(value);
        } else {
          setter(Math.ceil(start));
        }
      }, 100);
    };

    countToValue(2010, setFoundedYear);
    countToValue(40000, setAnnualSales);
    countToValue(50, setCountries);
  }, []);

  return (
    <div className="mx-auto">
      <div className="relative isolate overflow-hidden bg-gray-900 py-10 sm:py-16">
        <img
          src="./images/man-work.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center brightness-50 opacity-70"
        />
        <div className="relative text-center mb-4">
          <h1 className="text-3xl sm:text-4xl text-white font-bold relative z-10 drop-shadow-2xl">
            About Us
          </h1>
        </div>
      </div>

      {/* Who We Are Section */}
      <section className="who-we-are-about text-white py-20">
        <div className="container mx-auto flex flex-col md:flex-row items-center rounded-lg p-8">
          {/* Left Side - Image */}
          <div className="md:w-1/2 mb-8 pr-10 md:mb-0">
            <img
              src="./images/team.jpeg"
              alt="Our Company"
              className="w-full rounded-lg object-cover"
            />
          </div>
          {/* Right Side - Text */}
          <div className="md:w-1/2 text-center md:text-left px-4">
            <h1 className="text-3xl sm:text-4xl font-bold">Who We Are</h1>
            <p className="mt-4 text-lg text-gray-400">
              Brothers Alliance is also deeply committed to delivering
              high-quality solutions tailored to meet the specific needs of its
              clients. With a robust in-house design, engineering, and
              manufacturing capability, the company ensures stringent adherence
              to international standards such as ASME, API, and other regulatory


              codes. This allows them to provide efficient, durable, and safe
              equipment for critical industrial applications.
            </p>
            <p className="mt-4 text-lg text-gray-400">
              Beyond manufacturing, Brothers Alliance offers comprehensive
              services, including installation, commissioning, and maintenance
              support, ensuring seamless integration of its products into
              clients' operational workflows.
            </p>
            <p className="mt-4 text-lg text-gray-400">
              With a focus on sustainability and innovation, Brothers Alliance
              is well-positioned to continue its leadership in providing
              high-performance industrial solutions that support the evolving
              needs of industries worldwide.
            </p>
          </div>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto flex flex-col md:flex-row items-center bg-gray-100 rounded-lg p-8">
          {/* Left Side - Text */}
          <div className="md:w-1/2 text-center md:text-left px-4">
            <h2 className="text-3xl sm:text-4xl font-bold ">Our Mission</h2>
            <p className="mt-4 text-lg text-gray-600 pr-10">
              Our mission is to create innovative solutions that empower
              businesses and individuals to thrive in the digital age. We focus
              on the future and aim to stay ahead of industry trends.
            </p>
            <p className="mt-4 text-lg text-gray-600 pr-10 pb-10">
              Adhering to our principle of "quality first, win-win cooperation,"
              we are confident in supplying customers with high-quality products
              at very competitive prices. Union Steel Industry Co., Ltd will
              always be your most professional and reliable business partner.
            </p>
          </div>
          {/* Right Side - Image */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="./images/oil.jpg"
              alt="Our Mission"
              className="w-full rounded-lg object-cover"
            />
          </div>
        </div>
      </section>

      {/* Numbers Section with Counting Effect */}
      <section className="py-24 bg-gray-100">
        <div className="container mx-auto text-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-5xl sm:text-6xl font-bold">{foundedYear}</h3>
              <p className="mt-4 text-lg text-gray-600">Year Founded</p>
            </div>
            <div>
              <h3 className="text-5xl sm:text-6xl font-bold">{annualSales}</h3>
              <p className="mt-4 text-lg text-gray-600">Annual Sales ($)</p>
            </div>
            <div>
              <h3 className="text-5xl sm:text-6xl font-bold">{countries}</h3>
              <p className="mt-4 text-lg text-gray-600">Countries Served</p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-24 bg-white">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl sm:text-4xl font-bold">Get In Touch</h2>
          <p className="mt-4 text-lg text-gray-600">
            Feel free to reach out to us for any inquiries or collaborations.
          </p>
          <div className="mt-6 flex flex-col sm:flex-row justify-center gap-y-6 gap-x-8">
            <div className="text-center">
              <AiOutlineMail className="mx-auto text-3xl text-green-700" />
              <p className="mt-2 text-gray-600">info@brothersalliancetrading.com</p>
            </div>
            <div className="text-center">
              <AiOutlinePhone className="mx-auto text-3xl text-green-700" />
              <p className="mt-2 text-gray-600">+971 52 626 7933</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsContainer;
