import React from 'react';
import './features.css';
import { FaChartPie, FaThumbsUp, FaBoxOpen, FaChartLine, FaUserPlus, FaHeadset } from 'react-icons/fa';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-6 border rounded-lg shadow-md bg-white hover:scale-105 ease-in duration-200">
    <div className="text-4xl features-icon-color mb-4">
      <Icon />
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const FeaturesSection = () => {
  const features = [
    {
      icon: FaChartPie,
      title: 'Rich Experience',
      description: '20+ years experience in high quality steel pipe, petrochemical, chemical, fertilizer manufacturing, stocking and exporting',
    },
    {
      icon: FaThumbsUp,
      title: 'Competitive Price',
      description: 'Strong cooperation network with raw material mills contribute to a competitive price than market',
    },
    {
      icon: FaBoxOpen,
      title: 'Outstanding Quality',
      description: 'Perfect quality control process and strict & professional quality control team provide 100% quality assurance',
    },
    {
      icon: FaChartLine,
      title: 'Sales',
      description: 'Experience the difference of our personalized sales approach, where you are always our top priority.',
    },
    {
      icon: FaUserPlus,
      title: 'Onboarding',
      description: 'Our onboarding process is designed to be simple and intuitive, so you can start using our platform right away.',
    },
    {
      icon: FaHeadset,
      title: 'Support',
      description: 'Our commitment to exceptional support ensures that you receive the assistance you need, whenever you need it.',
    },
  ];

  return (
    <div className="py-24 bg-gray-300">
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">Why Choose Us</h2>
        <p className="text-gray-600 mb-12">
          At every step of our process, we prioritize the user, ensuring that our products and services are designed with their needs in mind.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-1">
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
